import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isMerchantsLoading: false,
  merchantsData: null,
  merchantsError: null,

  isNearbyMerchantsLoading: false,
  nearbyMerchantsData: null,
  nearbyMerchantsError: null,

  isReorderItemsLoading: false,
  reorderItemsData: null,
  reorderItemsError: null,

  currencyCode: null,

  isCatalogDetailsLoading: false,
  catalogDetails: null,
};

const loadMerchantsReducer = state => {
  state.isMerchantsLoading = true;
};
const loadNearbyMerchantsReducer = state => {
  state.isNearbyMerchantsLoading = true;
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    loadMerchants: loadMerchantsReducer,
    reloadMerchants: {
      prepare: ({ nextPageToken, ...payload }) => ({ payload }),
      reducer: loadMerchantsReducer,
    },
    loadMoreMerchants: state => {
      state.isMerchantsLoading = true;
    },
    loadMerchantsSuccess: (state, action) => {
      state.isMerchantsLoading = false;
      state.merchantsData = action.payload;
    },
    loadMoreMerchantsSuccess: (state, action) => {
      state.isMerchantsLoading = false;
      state.merchantsData.data.push(...action.payload.data);
      state.merchantsData.hasNextPage = action.payload.hasNextPage;
      state.merchantsData.paginationToken = action.payload.paginationToken;
    },
    loadMerchantsError: (state, action) => {
      state.isMerchantsLoading = false;
      state.merchantsError = action.payload.error;
    },
    loadNearbyMerchants: loadNearbyMerchantsReducer,
    reloadNearbyMerchants: {
      prepare: ({ nextPageToken, ...payload }) => ({ payload }),
      reducer: loadNearbyMerchantsReducer,
    },
    loadMoreNearbyMerchants: state => {
      state.isNearbyMerchantsLoading = true;
    },
    loadNearbyMerchantsSuccess: (state, action) => {
      state.isNearbyMerchantsLoading = false;
      state.nearbyMerchantsData = action.payload;
    },
    loadMoreNearbyMerchantsSuccess: (state, action) => {
      state.isNearbyMerchantsLoading = false;
      state.nearbyMerchantsData.data.push(...action.payload.data);
      state.nearbyMerchantsData.hasNextPage = action.payload.hasNextPage;
      state.nearbyMerchantsData.paginationToken =
        action.payload.paginationToken;
    },
    loadNearbyMerchantsError: (state, action) => {
      state.isNearbyMerchantsLoading = false;
      state.nearbyMerchantsError = action.payload.error;
    },
    updateCurrencyCode: (state, action) => {
      state.currencyCode = action.payload;
    },
    loadCatalogDetails: state => {
      state.isCatalogDetailsLoading = true;
    },
    loadCatalogDetailsSuccess: (state, action) => {
      state.catalogDetails = action.payload;
      state.isCatalogDetailsLoading = false;
    },
    loadCatalogDetailsError: {
      prepare: error => ({ payload: { error } }),
      reducer: (state, action) => {
        state.isCatalogDetailsLoading = false;
        state.catalogDetails = action.payload.error;
      },
    },
    loadReorderItems: state => {
      state.isReorderItemsLoading = true;
    },
    loadReorderItemsSuccess: (state, action) => {
      state.isReorderItemsLoading = false;
      state.reorderItemsData = action.payload;
    },
    loadReorderItemsError: (state, action) => {
      state.isReorderItemsLoading = false;
      state.reorderItemsError = action.payload.error;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.catalog);
  },
});

export const {
  loadMerchants,
  loadMoreMerchants,
  reloadMerchants,
  loadMerchantsSuccess,
  loadMoreMerchantsSuccess,
  loadMerchantsError,
  loadNearbyMerchants,
  loadMoreNearbyMerchants,
  reloadNearbyMerchants,
  loadNearbyMerchantsSuccess,
  loadMoreNearbyMerchantsSuccess,
  loadNearbyMerchantsError,
  updateCurrencyCode,
  loadCatalogDetails,
  loadCatalogDetailsSuccess,
  loadCatalogDetailsError,
  loadReorderItems,
  loadReorderItemsSuccess,
  loadReorderItemsError,
} = catalogSlice.actions;

export default catalogSlice.reducer;
