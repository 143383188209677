import {
  put,
  takeLatest,
  call,
  getContext,
  select,
  // delay,
} from 'redux-saga/effects';
import {
  getQuickReorder,
  getQuickReorderForMerchant,
} from '@artemis/api/athena';
import {
  getCatalogMerchants,
  getNearbyMerchants,
  getCatalogDetails,
} from '@artemis/api/contentGateway';
import { getUserExternalId } from '@artemis/store/user/selectors';
import { updateWorkspaceId } from '@artemis/store/workspace/slice';
import {
  loadMerchants,
  loadMerchantsSuccess,
  loadMoreMerchantsSuccess,
  loadMerchantsError,
  loadNearbyMerchantsSuccess,
  loadMoreNearbyMerchantsSuccess,
  loadNearbyMerchantsError,
  reloadMerchants,
  reloadNearbyMerchants,
  loadCatalogDetailsSuccess,
  loadCatalogDetailsError,
  loadMoreMerchants,
  loadNearbyMerchants,
  loadMoreNearbyMerchants,
  loadCatalogDetails,
  loadReorderItems,
  loadReorderItemsSuccess,
  loadReorderItemsError,
} from './slice';

export function* loadMerchantsSaga(action) {
  const { payload } = action;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(getCatalogMerchants, apiClient, { ...payload });
    yield put(loadMerchantsSuccess(data));
  } catch (error) {
    yield put(loadMerchantsError({ error }));
  }
}

export function* loadMoreMerchantsSaga(action) {
  const { payload } = action;
  const apiClient = yield getContext('contentGatewayApiClient');
  try {
    const { data } = yield call(getCatalogMerchants, apiClient, { ...payload });
    yield put(loadMoreMerchantsSuccess(data));
  } catch (error) {
    yield put(loadMerchantsError({ error }));
    // if it's failing because nextPageToken, re-run search
    if (
      error?.response?.data?.errors[0].parameter === 'nextPageToken' &&
      error?.response?.data?.errors[0].code === 'INVALID_PARAMETER'
    ) {
      yield put(reloadMerchants(payload));
    }
  }
}

export function* loadNearbyMerchantsSaga(action) {
  const { payload } = action;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const externalUserId = yield select(getUserExternalId);
    const { data } = yield call(getNearbyMerchants, apiClient, {
      externalUserId,
      ...payload,
    });
    yield put(loadNearbyMerchantsSuccess(data));
  } catch (error) {
    yield put(loadNearbyMerchantsError({ error }));
  }
}

export function* loadMoreNearbyMerchantsSaga(action) {
  const { payload } = action;
  const apiClient = yield getContext('contentGatewayApiClient');
  try {
    const externalUserId = yield select(getUserExternalId);
    const { data } = yield call(getNearbyMerchants, apiClient, {
      externalUserId,
      ...payload,
    });
    yield put(loadMoreNearbyMerchantsSuccess(data));
  } catch (error) {
    yield put(loadNearbyMerchantsError({ error }));
    // if it's failing because nextPageToken, re-run search
    if (
      error?.response?.data?.errors[0].parameter === 'nextPageToken' &&
      error?.response?.data?.errors[0].code === 'INVALID_PARAMETER'
    ) {
      yield put(reloadNearbyMerchants(payload));
    }
  }
}

export function* loadCatalogDetailsSaga(action) {
  const { payload } = action;
  const apiClient = yield getContext('contentGatewayApiClient');
  try {
    const { data, headers } = yield call(getCatalogDetails, apiClient, {
      ...payload,
    });

    // Unique identifier to be used for sending analytics events from the web app.
    // If the requested catalog is "Merchants List of a Workspace", then header's value is Workspace ID.
    // PR: https://github.com/RitualCo/openapi-specifications/pull/246/files
    yield put(loadCatalogDetailsSuccess(data));
    const analyticsId = headers['x-ritual-analytics-id'];
    yield put(updateWorkspaceId(analyticsId));
  } catch (err) {
    yield put(loadCatalogDetailsError(err));
  }
}

export function* loadReorderItemsSaga(action) {
  const { payload } = action;
  try {
    const apiClient = yield getContext('athenaApiClient');
    const isLatLngRequest = payload.latitude !== undefined;
    const request = isLatLngRequest
      ? getQuickReorder
      : getQuickReorderForMerchant;

    const { data } = yield call(request, apiClient, { ...payload });
    // TODO: remove these mocks
    // yield delay(2000);
    // const spp = [0, 1, 2, 3, 4, 5];
    // const spp = [
    //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    // ];
    // const data = {
    //   items: spp.map(i => ({
    //     merchant: {
    //       id: '4881230b58cc437a25670e02b2ce488',
    //       name: `Merchant ${i} (Lorem/Ipsum)`,
    //       address: {
    //         intersection: 'Lorem/Ipsum',
    //       },
    //       menuPath: '/488-mer/1ton',
    //     },
    //     item: {
    //       id: '81',
    //       name: '488 Gatorade',
    //       price: '$3.50',
    //       priceMicro: 350000000,
    //       imageUrl:
    //         'https://lh3.googleusercontent.com/lpTIkMGep4Q9RJYBmE0DFKXTKGeTwlhexsaLgz98Wf69x5oLXXzN_YZZzXQiTYKAdMLp1-aOD31wAj_YpE5oT-dD0LScmlHDWSa0oZk=c-w256',
    //     },
    //   })),
    // };
    yield put(loadReorderItemsSuccess(data.data));
  } catch (error) {
    yield put(loadReorderItemsError({ error }));
  }
}

export default function* catalogData() {
  yield takeLatest(loadMerchants.type, loadMerchantsSaga);
  yield takeLatest(reloadMerchants.type, loadMerchantsSaga);
  yield takeLatest(loadMoreMerchants.type, loadMoreMerchantsSaga);
  yield takeLatest(loadNearbyMerchants.type, loadNearbyMerchantsSaga);
  yield takeLatest(reloadNearbyMerchants.type, loadNearbyMerchantsSaga);
  yield takeLatest(loadMoreNearbyMerchants.type, loadMoreNearbyMerchantsSaga);
  yield takeLatest(loadCatalogDetails.type, loadCatalogDetailsSaga);
  yield takeLatest(loadReorderItems.type, loadReorderItemsSaga);
}
