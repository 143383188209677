import { createSelector } from 'reselect';

const getCatalogRoot = state => state.catalog;

export const getMerchantsData = createSelector(
  getCatalogRoot,
  catalog => catalog?.merchantsData?.data,
);

export const getIsMerchantsLoading = createSelector(
  getCatalogRoot,
  catalog => catalog?.isMerchantsLoading,
);

export const getHasNextPage = createSelector(
  getCatalogRoot,
  catalog => catalog?.merchantsData?.hasNextPage,
);

export const getPaginationToken = createSelector(
  getCatalogRoot,
  catalog => catalog?.merchantsData?.paginationToken,
);

export const getNearbyMerchantsData = createSelector(
  getCatalogRoot,
  catalog => catalog?.nearbyMerchantsData?.data,
);

export const getIsNearbyMerchantsLoading = createSelector(
  getCatalogRoot,
  catalog => catalog?.isNearbyMerchantsLoading,
);

export const getNearbyHasNextPage = createSelector(
  getCatalogRoot,
  catalog => catalog?.nearbyMerchantsData?.hasNextPage,
);

export const getNearbyPaginationToken = createSelector(
  getCatalogRoot,
  catalog => catalog?.nearbyMerchantsData?.paginationToken,
);

export const getCatalogCurrency = createSelector(
  getCatalogRoot,
  catalog => catalog?.currencyCode,
);

const getCatalogDetails = createSelector(
  getCatalogRoot,
  catalog => catalog?.catalogDetails,
);

export const getCatalogName = createSelector(
  getCatalogDetails,
  details => details?.name,
);

const getCatalogMerchantsData = createSelector(
  getCatalogDetails,
  details => details?.merchants,
);

export const getCatalogShowsNearbySection = createSelector(
  getCatalogMerchantsData,
  // Default to true
  merchantsDetails =>
    merchantsDetails?.hasNearbySection === undefined
      ? true
      : merchantsDetails?.hasNearbySection,
);

export const getCatalogShowsCategorySection = createSelector(
  getCatalogMerchantsData,
  getCatalogShowsNearbySection,
  // If showNearby is set, default to that, if it's not set, default to false
  (merchantsDetails, showNearby) =>
    merchantsDetails?.hasCategorySection === undefined
      ? showNearby
      : merchantsDetails?.hasCategorySection,
);

export const getCatalogLocation = createSelector(
  getCatalogMerchantsData,
  merchantsDetails => merchantsDetails?.location,
);

export const getCatalogSearchDistance = createSelector(
  getCatalogMerchantsData,
  merchantsDetails => merchantsDetails?.nearbySearchDistance,
);

export const getIsReorderItemsLoading = createSelector(
  getCatalogRoot,
  catalog => catalog?.isReorderItemsLoading,
);

export const getReorderItems = createSelector(
  getCatalogRoot,
  catalog => catalog?.reorderItemsData || [],
);
